import './LettersGrid.css';
import { Row } from "react-bootstrap";
import React from 'react';

function LettersGrid({letters={}}) {
    var grid = [];
    for (var i = 0; i < 30; i = i + 5) {
        grid.push(<Row key={i} className='px-2 my-1'><LettersRow letters={letters} row={i} /></Row>);
    }
    return (
        grid
    );
}

function LettersRow({letters={}, row=null}) {
    var letterRow = [];
    for (var i = 1; i < 6; i++) {
        letterRow.push(<LetterSquare key={i} square={i} row={row} letters={letters}/>);
    }
    return (letterRow);
}

function LetterSquare({square=null, row=null, letters={}}) {
    var letterPosition = square + row - 1;
    return (
        <div 
            data-position={letterPosition} 
            data-rowposition={square} 
            className={
                `tile 
                mx-1 
                letter ${letters[letterPosition] !== undefined ? letters[letterPosition].class : "bg-secondary"} 
                ${letters.length === letterPosition ? 'activeLetter' : ''}`}>
                    {letters[letterPosition] !== undefined ? letters[letterPosition].value : ""}
        </div>
    );
}

export default LettersGrid;