import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import './App.css';
import Header from './components/Header';
import LettersGrid from './components/LettersGrid';
import Results from './components/Results';

function App() {
  const [letters, setLetters] = useState([]);
  const [results, setResults] = useState([]);
  const [language, setLanguage] = useState("en");  
  const handleChangeLetterState = e => {
    let letterContent = e.target.innerHTML;
    let letterArrayPosition = e.target.dataset.position;
    let letterRowPosition = e.target.dataset.rowposition;
    if(letterContent && letterArrayPosition) {
      let letterStatus = letters[letterArrayPosition].status;
      let newLetterStatus = 0;
      let newsLetterClass = "bg-dark";

      if(letterStatus === 0) {
        newLetterStatus = 1;
        newsLetterClass = "bg-warning";
      } else if (letterStatus === 1) {
        newLetterStatus = 2;
        newsLetterClass = "bg-success";
      } else {
        newLetterStatus = 0;
        newsLetterClass = "bg-dark";
      }

      let items = letters;
      let item = {...items[letterArrayPosition]};
      item.status = newLetterStatus;
      item.class = newsLetterClass;
      item.rowposition = letterRowPosition;
      items[letterArrayPosition] = item;
      setLetters([...items]);
    }
  };

  const handleKeyPress = e => {
    let key = e.key;
    if (key === 'Backspace') {
      setLetters((letters) => letters.filter((_, i) => i !== letters.length - 1));
    } else {
      if (key.match(/^[a-zA-Z]{1}$/) && letters.length < 30) {
        setLetters([...letters, {value: key, status: 0, position: letters.length + 1, rowposition: getRowPositionFromLetterPosition(letters.length + 1), class: "bg-dark"}]);
      } else {
        return;
      }
    }
  };

  function getRowPositionFromLetterPosition(letterPosition=null) {
    if(!letterPosition) {
      return null;
    }
    if (letterPosition === 1 || letterPosition === 6 || letterPosition === 11 || letterPosition === 16 || letterPosition === 21 || letterPosition === 26) {
      return 1;
    } else if (letterPosition === 2 || letterPosition === 7 || letterPosition === 12 || letterPosition === 17 || letterPosition === 22 || letterPosition === 27) {
      return 2;
    } else if (letterPosition === 3 || letterPosition === 8 || letterPosition === 13 || letterPosition === 18 || letterPosition === 23 || letterPosition === 28) {
      return 3;
    } else if (letterPosition === 4 || letterPosition === 9 || letterPosition === 14 || letterPosition === 19 || letterPosition === 24 || letterPosition === 29) {
      return 4;
    } else if (letterPosition === 5 || letterPosition === 10 || letterPosition === 15 || letterPosition === 20 || letterPosition === 25 || letterPosition === 30) {
      return 5;
    }
  }

  const handleClear = e => {
    setLetters([]);
    setResults([]);
  }

  const handleSubmit = e => {
    validateLetters(letters);
    let bodyData = JSON.stringify({letters, language});
    fetch('php/search.php', {
      body: bodyData,
      cache: 'no-cache',
      headers: {
        'content-type': 'application/json'
      },
      method: 'POST'
    })
    .then((response) => {
      //console.log(response);
      return response.json();
    })
    .then(data => {
      //console.log(data);
      setResults(data);
    }).catch(err => {
      console.log(err);
      console.log(e);
    })
  };

  // autofocus, not good solution
  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current.focus();
  }, [letters]);

  const handleChangeLanguage = e => {
    if(language === "sk") {
      setLanguage("en");
    } else {
      setLanguage("sk");
    }
  };

  /* VALIDATE LETTERS IN GRID, REPLACE WRONG LETTERS & STATE WITH CORRECT GREEN */
  function validateLetters(letters) {
    let items = letters;
    letters.forEach((letter) => {
      if(letter.status === 2) {
        for (let i = letter.position - 1; i <= letters.length; i+=5) {
          let item = {...items[i]};
          item.rowposition = getRowPositionFromLetterPosition(letter.position);
          item.value = letter.value;
          item.status = 2;
          item.class = "bg-success";
          items[i] = item;
          setLetters([...items]);
        }
      }
    });
  }

  /* MODAL */
  const [showTutorial, setShowTutorial] = useState(false);
  const handleCloseTutorial = () => setShowTutorial(false);
  const handleShowTutorial = () => setShowTutorial(true);
  
  return (
    <React.Fragment>
      <div className={"wrapper"} onKeyDown={handleKeyPress} tabIndex="-1" onClick={handleChangeLetterState} ref={inputRef}>
        <Header onClick={handleChangeLanguage} language={language} />
        <Container>
          <Row>
            <Col className={"flex-center"} lg={12} md={12} sm={12}>
              <LettersGrid letters={letters} />
              <div>
                <Button className={"btn-lg btn-main my-4"} onClick={handleSubmit}>🔎</Button>
                <Button className={"btn-danger btn-lg btn-main my-4"} onClick={handleClear}>🗑️</Button>
                <Button className={"btn-warning btn-lg btn-main my-4"} onClick={handleShowTutorial}>ℹ️</Button>
              </div>
            </Col>
            <Col lg={12} md={12} sm={12}>
              <Results results={results} letters={letters} onClick={() => handleSubmit}/>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal show={showTutorial} onHide={handleCloseTutorial}>
        <Modal.Header closeButton>
          <Modal.Title>ℹ️</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{textAlign: 'center'}}>
          <h2 className='text-center'>⌨️ ➕ 🖱️</h2>
          <img alt="" className='shadow' style={{maxHeight: 600, marginTop: 15}} src='tut.gif' />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default App;
