import { Col, Container, Row } from "react-bootstrap";
import './Header.css';

function Header({onClick=()=>{}, language="en"}) {
    return (
        <header>
            <Container>
                <Row>
                    <Col>
                        <h1 style={{letterSpacing: '-10px'}} className={"display-4 text-center mt-5 mb-2"}>⬛️⬛️<br />🟨🟩</h1>
                    </Col>
                </Row>
                <Row>
                    <Col className={"text-center my-4"}>
                        <span className={`switch-text ${language === "en" ? "active" : ""}`}>🇺🇸</span>
                        <label className={"switch"}>
                            <input onClick={onClick} name="lang" id="lang_switch" defaultChecked={language === "sk"} type="checkbox" />
                            <span className={"slider"}></span>
                        </label>
                        <span className={`switch-text ${language === "sk" ? "active" : ""}`}>🇸🇰</span>
                    </Col>
                </Row>
            </Container>
        </header>
    );
}

export default Header;