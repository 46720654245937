import React from "react";
import { Col, Row } from "react-bootstrap";

function Results({results=[], letters=[]}) {
    let returnData;
    if (letters.length === 0) {
        returnData = emptyResult();
    } else if (results.length === 0) {
        returnData = emptyResult();
    } else {
        returnData = returnResults({results});
    }
    return (
        <Row className={"results-temp text-center my-2"}>{returnData}</Row>
    );
}

function returnResults({results=[]}) {
    return results.map((r, i) => (
        <Col xs={2} key={i} className={"text-light lead"}>
            <div className={"hint m-1"}>{r.word}</div>
        </Col>
    ));
}

function emptyResult() {
    return (<h1>🕳️</h1>);
}

export default Results;